import { useIsAdmin } from "@/app/store/auth/hooks"
import { type DefaultParams, Redirect, Route, type RouteProps } from "wouter"

/**
 * RouteAdmin
 */
export const RouteAdmin: React.FC<RouteAuthenticatedProps> = ({
  redirect = "/sign-in",
  children,
  ...props
}) => {
  const isAdmin = useIsAdmin()
  return (
    <>
      {isAdmin ? (
        <Route {...props}>
          {G.isFunction(children) ? (params: DefaultParams) => children(params) : children}
        </Route>
      ) : (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      )}
    </>
  )
}
type RouteAuthenticatedProps = Extend<
  RouteProps,
  {
    redirect?: string
  }
>
