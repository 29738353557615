export const i18nConfig = {
  defaultLocale: "en",
  fallbackLocale: "en",
  locales: ["en", "fr", "de"],
  debug: false && process.env.VITE_ENV === "development",
  defaultNamespace: "translation" as const,
  localeCodes: {
    en: "en-US",
    fr: "fr-FR",
    de: "de-DE",
  } as Record<string, string>,
}

export type Locale = (typeof i18nConfig)["locales"][number]
