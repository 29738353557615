import { create } from "zustand"
import { AuthStoreState } from "./types"

/**
 * initialState
 */
const initialState: AuthStoreState = {
  initDone: false,
  initError: false,
  authenticated: false,
  vid: "",
  user: null,
}

/**
 * Store
 */
export const useAuthStore = create<AuthStoreState>()(() => initialState)
