import { useIsMember } from "@/app/store/auth/hooks"
import { DefaultParams, Redirect, Route, RouteProps } from "wouter"

/**
 * RouteMember
 */
export const RouteMember: React.FC<RouteAuthenticatedProps> = ({
  redirect = "/sign-in",
  children,
  ...props
}) => {
  const isMember = useIsMember()
  return (
    <>
      {isMember ? (
        <Route {...props}>
          {G.isFunction(children) ? (params: DefaultParams) => children(params) : children}
        </Route>
      ) : (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      )}
    </>
  )
}
type RouteAuthenticatedProps = Extend<
  RouteProps,
  {
    redirect?: string
  }
>
