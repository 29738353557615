import Header from "@/components/layouts/Website/Header"
import Main from "@/components/layouts/Website/Main"
import { Spinner } from "@phosphor-icons/react"

/**
 * AppLoader
 */
const AppLoader: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Main>
        <Loader />
      </Main>
    </div>
  )
}

export default AppLoader

export const Loader: React.FC = () => {
  return (
    <div
      className={cx(
        "py-24 lg:py-52 flex justify-center items-center w-full max-w-screen-2xl mx-auto"
      )}
    >
      <Spinner className="fill-primary-800/50 animate-slow-rotate" size={24} weight="thin" />
    </div>
  )
}
