import { usePromise } from "@/app/hooks/usePromise"
import useUuid from "@/app/hooks/useUuid"
import { useAuthStore } from "@/app/store/auth"
import { init } from "@/app/store/auth/actions"
import { useLanguagesStore } from "@/app/store/languages"
import { loadLanguages } from "@/app/store/languages/actions"
import { Toasts } from "@/components/ui/Toasts"
import AppError from "@/pages/app-error"
import AppLoader from "@/pages/app-loader"
import Routes from "./Routes"
import { useDictionary } from "@/app/hooks/useDictionary"

/**
 * App
 */
const App: React.FC = () => {
  const authInitialized = useAuthStore(state => state.initDone)
  const languagesLoaded = useLanguagesStore(state => state.initDone)

  // set current language on first load
  const { language } = useDictionary()
  React.useEffect(
    () =>
      document?.documentElement.setAttribute(
        "lang",
        pipe(language, S.split("-"), A.map(S.trim), A.getUnsafe(0), S.toLowerCase)
      ),
    [language]
  )

  // init stores
  const vid = useUuid()
  const [, loadingInit] = usePromise(() => init(vid))
  const [, loadingLangs] = usePromise(loadLanguages)

  // loading
  if (loadingInit || loadingLangs) {
    return <AppLoader />
  }

  // init errors
  if (!languagesLoaded || !authInitialized) {
    return <AppError />
  }

  return (
    <>
      <Routes />
      <Toasts />
    </>
  )
}

export default App
