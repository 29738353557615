import { MenuLinkType, menu1, menu2 } from "@/app/config/pages"
import { isActivePath } from "@/app/fns/path"
import { useDictionary } from "@/app/hooks/useDictionary"
import useResponsive from "@/app/hooks/useResponsive"
import { useToggle } from "@/app/hooks/useToggle"
import { useAuthStore } from "@/app/store/auth"
import { useIsAdmin, useIsMember } from "@/app/store/auth/hooks"
import logoVp from "@/assets/logo-vp.png"
import { ButtonIcon } from "@/components/ui/Button"
import Container from "@/components/ui/Container"
import { LanguageDropdown } from "@/components/ui/LanguageDropdown"
import { Link } from "@/components/ui/Link"
import Spinner from "@/components/ui/Spinner"
import Wrapper from "@/components/ui/Wrapper"
import { List, User, X } from "@phosphor-icons/react"
import React, { HTMLAttributes } from "react"
import { useLocation } from "wouter"

/**
 * Header
 */
const Header: React.FC = () => {
  const mobileMenu = useToggle()

  React.useEffect(() => {
    mobileMenu.state
      ? document.body.classList.remove("overflow-hidden")
      : document.body.classList.add("overflow-hidden")
  }, [mobileMenu.state])

  const media = useResponsive()
  React.useEffect(() => {
    if (media.min("lg")) mobileMenu.close()
  }, [media.min("lg")])

  const isDisplay = useIsDisplay()

  return (
    <Context.Provider value={{ mobileMenu }}>
      <header
        className={cx(
          mobileMenu.state && "overflow-scroll fixed w-full h-screen bg-white z-[9999]",
          "text-sm lg:text-xs xl:text-sm"
        )}
      >
        <Wrapper className={cx(mobileMenu.state && "h-screen")}>
          <Container
            x="sm"
            className={cx(
              "flex flex-col lg:flex-row lg:items-center py-5",
              mobileMenu.state && "grow"
            )}
          >
            <nav className="flex justify-between items-center w-full lg:w-auto">
              {/* logo */}
              <Link href="/">
                <span>
                  <img
                    className="h-10 lg:h-8 xl:h-10"
                    src={logoVp}
                    alt={"VP: Voie de préparation"}
                  />
                </span>
              </Link>

              {/* mobile menu toggle */}
              <ButtonIcon
                className={cx(
                  "[&>svg]:w-5 [&>svg]:h-5",
                  mobileMenu.state ? "bg-orient" : "lg:hidden"
                )}
                variant={"dark"}
                size={"icon"}
                onClick={mobileMenu.toggle}
              >
                {mobileMenu.state ? <X /> : <List />}
              </ButtonIcon>
            </nav>

            <div
              className={cx(
                "lg:flex w-full flex-col lg:flex-row lg:items-center gap-5 lg:gap-3 xl:gap-5",
                mobileMenu.state ? "flex grow" : "hidden"
              )}
            >
              {/* nav menu 1 */}
              <nav className="flex grow lg:items-center pt-8 lg:pt-0">
                <ul className="flex justify-start lg:grow lg:justify-end gap-5 lg:gap-x-3.5 xl:gap-x-[30px] lg:flex-row flex-col">
                  {A.filterMap(menu1, link =>
                    isDisplay(link) ? <Menu1Link link={link} key={link.id} /> : O.None
                  )}
                </ul>
              </nav>

              <Divider />

              {/* nav menu 2 */}
              <nav className="flex flex-wrap items-center justify-center">
                <ul className="gap-x-[10px] lg:gap-x-1.5 xl:gap-x-[10px] grid grid-cols-2 lg:flex justify-end w-full lg:w-auto">
                  {A.filterMap(menu2, link =>
                    isDisplay(link) ? <Menu2Link link={link} key={link.id} /> : O.None
                  )}
                </ul>
              </nav>

              <Divider />

              {/* nav auth & languages */}
              <nav className="flex items-center gap-5 lg:gap-3 xl:gap-3">
                <AuthLink />
                <Divider className={cx("hidden lg:flex")} />
                <LanguageDropdown />
              </nav>
            </div>
          </Container>
        </Wrapper>
      </header>
    </Context.Provider>
  )
}
export default Header

/**
 * Menu1Link
 */
const Menu1Link: React.FC<MenuLinkProps> = ({ link: { title, path, exact = false } }) => {
  const { _ } = useDictionary("pages.layout.header.menu")
  const [currentPath] = useLocation()
  const { mobileMenu } = React.useContext(Context)
  const isActive = isActivePath(path, currentPath, exact)
  return (
    <li>
      <Link
        href={path}
        className={cx(
          "group/link relative lg:flex flex-col inline-block lg:mb-0 text-emperor underline-offset-8 decoration-2",
          "transition-all outline-none focus-visible:font-medium",
          isActive
            ? "text-orient underline lg:no-underline"
            : "border-transparent hover:text-orient hover:underline lg:hover:no-underline focus-visible:underline lg:focus-visible:no-underline  focus-visible:text-orient"
        )}
        onClick={mobileMenu.close}
      >
        {_(title)}
        <span
          aria-hidden
          className={cx(
            "absolute top-6 hidden lg:block bg-orient h-0.5 w-full",
            isActive
              ? "scale-x-100"
              : "scale-x-0 group-hover/link:scale-x-100 transition-transform duration-300 group-focus-visible/link:scale-x-100"
          )}
        />
      </Link>
    </li>
  )
}

/**
 * Menu2Link
 */
const Menu2Link: React.FC<MenuLinkProps> = ({ link: { title, path, exact = false } }) => {
  const { _ } = useDictionary("pages.layout.header.menu")
  const [currentPath] = useLocation()
  const { mobileMenu } = React.useContext(Context)
  return (
    <li>
      <Link
        className={cx(
          "inline-flex justify-center items-center h-[46px] w-full lg:w-auto",
          "border-[1px] border-mercury text-shark hover:border-orient focus:bg-aquahaze active:bg-orient active:border-orient active:text-white px-4 lg:px-2 xl:px-4",
          "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-500 focus-visible:ring-offset-2",
          "focus:shadow-[0_0px_4px_0px] focus:shadow-matisse focus:border-[1px] focus:border-orient",
          "disabled:pointer-events-none disabled:opacity-50 disabled:bg-cloud",
          "transition-colors",
          isActivePath(path, currentPath, exact) ? "text-orient border-orient" : ""
        )}
        href={path}
        onClick={mobileMenu.close}
      >
        {_(title)}
      </Link>
    </li>
  )
}

/**
 * Divider
 */
const Divider: React.FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...props }) => {
  return (
    <span
      aria-hidden
      className={cx(
        "flex border-b-[1px] lg:border-l-[1px] w-full lg:w-0 border-mercury lg:h-[30px]",
        className
      )}
      {...props}
    />
  )
}

/**
 * AuthLink
 */
const AuthLink: React.FC = () => {
  const { _ } = useDictionary("pages.layout.header")
  const { mobileMenu } = React.useContext(Context)
  const { authenticated, initDone } = useAuthStore()

  return (
    <div className="grow lg:grow-0 flex justify-center">
      <Link
        className="flex items-center h-[46px] px-4 lg:px-2 xl:px-4 gap-x-2  text-shark font-medium animate-appear-opacity [&_svg]:w-4 [&_svg]:h-4 xl:[&_svg]:w-5 xl:[&_svg]:h-5"
        href={authenticated ? "/sign-out" : "/sign-in"}
        onClick={mobileMenu.close}
      >
        {!initDone ? <Spinner /> : <User />}
        {authenticated ? _("sign-out") : _("sign-in")}
      </Link>
    </div>
  )
}

/**
 * hooks
 */
const useIsDisplay = () => {
  const isMember = useIsMember()
  const isAdmin = useIsAdmin()
  const isDisplay = (item: MenuLinkType) => {
    if (G.isNotNullable(item.member)) return item.member === isMember
    if (G.isNotNullable(item.admin)) return item.admin === isMember
    if (G.isNotNullable(item.auth)) return item.auth === (isAdmin || isMember)
    return true
  }
  return isDisplay
}

/**
 * Context
 */
type ContextType = {
  mobileMenu: ReturnType<typeof useToggle>
}
const Context = React.createContext<ContextType>({} as ContextType)

/**
 * types
 */
type MenuLinkProps = {
  link: MenuLinkType
}
