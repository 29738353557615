let scopesCount = 0

export const createIdsScope = (start = 0) => {
  scopesCount = scopesCount + 1

  const current = {
    scope: scopesCount,
    count: start,
  }

  return {
    nextId: () => {
      current.count = current.count + 1
      return `${scopesCount.toString(16)}.${current.count.toString(16)}`
    },
  }
}
