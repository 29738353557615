/**
 * Wrapper
 */
type WrapperProps = {
  className?: string
  fixeHeight?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
  id?: string
}
const Wrapper: React.FC<WrapperProps> = ({ className, fixeHeight, children, ...props }) => {
  return (
    <div
      className={cx("flex flex-col justify-center w-full", fixeHeight && "h-[832px]", className)}
      {...props}
    >
      {children}
    </div>
  )
}
export default Wrapper
