import { create } from "zustand"
import { LanguagesStoreState } from "./types"

/**
 * initialState
 */
const initialState: LanguagesStoreState = {
  initDone: false,
  languages: {},
}

/**
 * store
 */
export const useLanguagesStore = create<LanguagesStoreState>()(() => initialState)
