import ReactDOM from "react-dom/client"
import App from "@/pages/App"
import "@/styles/global.css"
import "@/dictionaries/i18n"

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
