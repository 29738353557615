import i18next from "i18next"
import languagedetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { i18nConfig, Locale } from "@/app/config/i18n"
import en from "./en"
import de from "./de"
import fr from "./fr"

/**
 * merge dictionaries
 */
const dictionaries: Record<Locale, Dictionaries> = { en, de, fr }

/**
 * i18next configuration
 */
i18next.use(initReactI18next).use(languagedetector)
// i18next.on("languageChanged", language => document?.documentElement.setAttribute("lang", language)) // done in app.tsx
i18next.init({
  fallbackLng: i18nConfig.fallbackLocale,
  debug: i18nConfig.debug,
  defaultNS: i18nConfig.defaultNamespace,
  supportedLngs: [...D.values(i18nConfig.localeCodes), ...i18nConfig.locales],
  resources: A.reduce(i18nConfig.locales, {}, (resources, locale) => ({
    ...resources,
    [locale]: {
      [i18nConfig.defaultNamespace]: dictionaries[locale] ?? {},
    },
  })),
})
