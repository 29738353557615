import { createFetcher, request } from "@101studios/request"
import globalConfig from "../config/global"
import { makePath } from "@/app/fns/File"

export const getSessionToken = () =>
  import.meta.env.VITE_APP_SESSION_KEY
    ? localStorage.getItem(import.meta.env.VITE_APP_SESSION_KEY)
    : null

export const apiFetcher = createFetcher((url, opts) => {
  // const sessionToken = getSessionToken()
  return request(makePath(globalConfig.api, url), {
    ...opts,
    throwCanceled: false,
    throwNetwork: false,
    credentials: "include",
    headers: {
      ...opts?.headers,
      // ...(sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}),
    },
  })
})

const api = {
  get: <R extends FetcherResponse>(...p: Parameters<typeof apiFetcher.get>) =>
    apiFetcher.get<R[0], R[1]>(...p),
  post: <R extends FetcherResponse>(...p: Parameters<typeof apiFetcher.get>) =>
    apiFetcher.post<R[0], R[1]>(...p),
  put: <R extends FetcherResponse>(...p: Parameters<typeof apiFetcher.get>) =>
    apiFetcher.put<R[0], R[1]>(...p),
  delete: <R extends FetcherResponse>(...p: Parameters<typeof apiFetcher.get>) =>
    apiFetcher.delete<R[0], R[1]>(...p),
  patch: <R extends FetcherResponse>(...p: Parameters<typeof apiFetcher.get>) =>
    apiFetcher.patch<R[0], R[1]>(...p),
}
export type FetcherResponse = [Record<string, any>, Record<string, any>]
export default api
