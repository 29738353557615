import { useTranslation } from 'react-i18next'
import { i18nConfig } from '../config/i18n'

/**
 * useTranslation
 */
export const useDictionary = (prefix?: string) => {
  const { t, i18n, ...rest } = useTranslation<string, typeof i18nConfig.defaultNamespace>(
    i18nConfig.defaultNamespace
  )
  const { language, languages, changeLanguage } = i18n

  const _ = React.useCallback(
    (path: string | string[], options?: Parameters<typeof t>[1]): string => {
      const context = G.isString(path)
        ? G.isString(prefix) && S.isNotEmpty(prefix)
          ? `${prefix}.${path}`
          : path
        : G.isArray(path)
        ? [prefix, ...path]
        : path

      const translation = t(...([context, options] as Parameters<typeof t>))

      return G.isString(translation) ? translation : ''
    },
    [t, i18n]
  )
  return { _, language, languages, changeLanguage, t, i18n, ...rest }
}
