import globalConfig from "@/app/config/global"
import { useAuthStore } from "."

/**
 * Hooks
 */

export const useMe = () => {
  const me = useAuthStore(D.prop("user"))
  return me
}
export const useIsAdmin = () => {
  const me = useMe()
  return G.isNotNullable(me) ? me.role === "admin" : false
}
export const useIsMember = () => {
  const isDevMode = globalConfig.environment === "development"
  const me = useMe()
  return G.isNotNullable(me) ? isDevMode || me.role === "member" : false
}

/**
 * useAuth
 */

export const useAuth = () => {
  const authState = useAuthStore(state => state)
  return { ...authState, authenticated: G.isObject(authState.user) }
}
