import Header from "@/components/layouts/Website/Header"
import Main from "@/components/layouts/Website/Main"
import { Button } from "@/components/ui/Button"
import { useRoute } from "wouter"
import { CaretLeft } from "@phosphor-icons/react"

/**
 * App error <Page/>
 */

const Page: React.FC = () => {
  const [isHome] = useRoute("/")

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <Main>
        <div
          className={cx(
            "py-24 px-10 text-center flex gap-12 flex-col lg:py-52 justify-center items-center w-full max-w-screen-2xl mx-auto"
          )}
        >
          <h1 className="font-medium text-2xl text-gray">Failed to load this page.</h1>

          <div className="flex items-center gap-4 flex-wrap justify-center">
            <Button disabled={isHome} variant={"dark"} onClick={() => navigate("/")}>
              <CaretLeft size={16} />
              Home
            </Button>

            <Button onClick={() => window.location.reload()}>Retry</Button>
          </div>
        </div>
      </Main>
    </div>
  )
}

export default Page
