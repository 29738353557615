import { useAuthStore } from "@/app/store/auth"
import { Redirect, Route, RouteProps, DefaultParams } from "wouter"

/**
 * RouteAuthenticated
 */
export const RouteAuthenticated: React.FC<RouteAuthenticatedProps> = ({
  redirect = "/sign-in",
  children,
  ...props
}) => {
  const { authenticated } = useAuthStore()
  return (
    <>
      {authenticated ? (
        <Route {...props}>{G.isFunction(children) ? (params: DefaultParams) => children(params) : children}</Route>
      ) : (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      )}
    </>
  )
}
type RouteAuthenticatedProps = Extend<
  RouteProps,
  {
    redirect?: string
  }
>
