/**
 * pages
 */
const pages = {
  home: "7f6bb1c5-96c8-4a47-9893-ca94c65e24f3",
  news: "1ef518f7-ce86-4664-8e83-2287cbc692f9",
  contact: "bbf8f2c9-fc51-4da5-856b-68f46012b25f",
  kulturrallye: "7c297560-1604-40e0-b8b8-d9f86deffd39",
  offer: "b04e5917-8ded-4ac6-8946-9306da01c678",
  vpDay: "8cde762c-c200-4782-8f57-16afadf5756b",
  programs: "00139a29-2eda-4e15-9991-e23286a3a8a3",
  projects: "4d843fc4-3eae-4a85-b19d-d44b9cdf73ca",
  legalNotice: "c6000d33-0cbb-47df-8af3-efcfc4a3a61c",
  dataProtection: "d53e5bdd-4882-4767-9a01-6926cb469548",
}
export const menu1: MenuLinkType[] = [
  { id: pages.home, title: "homepage", path: "/", exact: true },
  { id: pages.projects, title: "projects", path: "/projects", member: true },
  { id: pages.news, title: "news", path: "/news" },
  { id: pages.programs, title: "program", path: "/programs", member: true },
  { id: pages.offer, title: "offer", path: "/offer" },
  { id: pages.contact, title: "contact", path: "/contact" },
]
export const menu2: MenuLinkType[] = [
  { id: pages.kulturrallye, title: "kulturrallye", path: "/kulturrallye", member: true },
  { id: pages.vpDay, title: "vp-day", path: "/vp-day" },
]

export default pages

/**
 * types
 */
export type MenuLinkType = {
  id: string
  title: string
  path: string
  exact?: boolean
  auth?: boolean
  admin?: boolean
  member?: boolean
  children?: MenuLinkType[]
}
