import { stressBy } from "@/app/fns/Array"
import { useToastsStore } from "."

/**
 * Hooks
 */

export const useSortedToasts = () => {
  const toastsById = useToastsStore((state) => state.toasts)

  const sortedToasts = React.useMemo(() => {
    const toasts = Object.values(toastsById)
    return stressBy(
      A.sort(toasts, (a, b) => b.created - a.created),
      (toast) => toast.type !== "loader"
    )
  }, [toastsById])

  return sortedToasts
}
