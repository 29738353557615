import { useAuthStore } from "@/app/store/auth"
import { Redirect, Route, RouteProps } from "wouter"

/**
 * RouteUnauthenticated
 */
export const RouteUnauthenticated: React.FC<React.PropsWithChildren<RouteAuthenticatedProps>> = ({
  redirect = "/",
  children,
  ...props
}) => {
  const { authenticated } = useAuthStore()
  return (
    <>
      {authenticated ? (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      ) : (
        <Route {...props}>{children}</Route>
      )}
    </>
  )
}
type RouteAuthenticatedProps = RouteProps & {
  redirect?: string
}
