import { create } from "zustand"
import { ToastsStoreState } from "./types"

/**
 * Store
 */

const initialState: ToastsStoreState = {
  toasts: {},
}

export const useToastsStore = create<ToastsStoreState>()(() => initialState)
