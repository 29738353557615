import { createIdsScope } from "@/app/fns/ids"
import { evolve_, Patch } from "evolve-ts"
import { useToastsStore } from "."
import { ActionResult } from ".."
import { PartialToast, Toast, ToastsStoreState } from "./types"

const { setState: set } = useToastsStore
const setPartial = (patch: Patch<ToastsStoreState>) => set(state => evolve(patch, state))
const toastId = createIdsScope()

/**
 * Actions
 */

export const deleteToast = (id: string) => setPartial({ toasts: D.deleteKey(id) })
export const updateToast = (toast: Extend<PartialToast, Pick<Toast, "id">>) =>
  setPartial({ toasts: D.set(toast.id, toast) })

export const addToast = ({
  key = null,
  type = "default",
  content,
  duration = "none",
}: PartialToast): Toast => {
  const toast: Toast = {
    id: toastId.nextId(),
    key,
    type,
    show: true,
    content,
    created: +new Date(),
    duration,
  }

  // removing toasts with dupe key
  if (toast.key) set(evolve_({ toasts: D.reject(t => t.key === toast.key) }))

  // add toast
  setPartial({ toasts: D.set(toast.id, toast) })

  return toast
}

export const addTimedToast = (partialToast: PartialToast, duration = 4000) => {
  const { id } = addToast({ ...partialToast, duration })

  // delete toast after timeout
  setTimeout(() => deleteToast(id), duration)
}

export const promiseResultToast = async <R extends ActionResult>(
  promise: R,
  content: Partial<{ loading: string; error: string; success: string; key: string }>
) => {
  // show loader toast
  const toastId = content.loading
    ? addToast({
        type: "loader",
        key: content.key,
        content: content.loading,
      }).id
    : null

  // await promise result
  const result = await promise

  if (toastId) deleteToast(toastId)

  return resultToast(result, content)
}

export const resultToast = <R extends { error: boolean }>(
  result: R,
  content: Partial<{ loading: string; error: string; success: string; key: string }>
) => {
  if (content.error && result.error)
    addTimedToast({
      content: content.error,
      key: content.key,
      type: "error",
    })

  if (content.success && !result.error)
    addTimedToast({
      content: content.success,
      key: content.key,
      type: "success",
    })

  return result
}

export const asyncActionToast = async <T extends { error: boolean }>(
  actionResult: Promise<T>,
  loading: string,
  success: string,
  error: string,
  key?: string | null,
  duration = 4000
) => {
  const toast = addToast({
    type: "loader",
    content: loading,
    key,
  })
  const result = await actionResult
  if (result.error)
    updateToast({
      ...toast,
      content: error,
      type: "error",
      duration,
    })
  else
    updateToast({
      ...toast,
      content: success,
      type: "success",
      duration,
    })
  setTimeout(() => deleteToast(toast.id), duration)
  return result
}
