import { createBodyPortal } from "@/app/fns/portals"
import { deleteToast } from "@/app/store/toasts/actions"
import { useSortedToasts } from "@/app/store/toasts/hooks"
import { Toast as ToastType } from "@/app/store/toasts/types"
import { Bug, CheckCircle, Info, Spinner, WarningCircle, X } from "@phosphor-icons/react"

const scheme = {
  base: cx(
    "flex flex-col w-full pointer-events-auto overflow-hidden",
    "rounded-md ring-1 ring-slate-800/10 shadow-md bg-white",
    "text-left",
    "animate-toast-appear"
  ),
  default: "text-slate-800 ring-slate-800/20 shadow-slate-800/10",
  success: "text-green-800 ring-green-800/20 shadow-green-800/10",
  error: "text-red-500 ring-red-800/20 shadow-red-800/10",
  loader: "text-blue-500 ring-blue-800/20 shadow-blue-800/10",
  debug: "text-orange-500 ring-orange-800/20 shadow-orange-800/10",
  progress: {
    base: "flex w-full h-1 rounded-l-full animate-progress-reverse",
    default: "bg-slate-800/20",
    success: "bg-green-800/20",
    error: "bg-red-800/20",
    loader: "bg-blue-800/20",
    debug: "bg-orange-800/20",
  },
}

/**
 * Toast
 */
export const Toast: React.FC<{ toast: ToastType }> = ({
  toast: { id, type, content, duration },
}) => {
  return (
    <button tabIndex={-1} onClick={() => deleteToast(id)} className={cx(scheme.base, scheme[type])}>
      <span className="flex w-full">
        {G.isNotNullable(type) && (
          <span className="flex justify-center items-center w-12 h-12 shrink-0">
            {type === "default" && <Info className="w-4 h-4 fill-current" />}
            {type === "success" && <CheckCircle className="w-4 h-4 fill-current" />}
            {type === "error" && <WarningCircle className="w-4 h-4 fill-current" />}
            {type === "loader" && <Spinner className="w-4 h-4 fill-current animate-slow-rotate" />}
            {type === "debug" && <Bug className="w-4 h-4 fill-current" />}
          </span>
        )}
        <span className="flex grow py-[13px] text-sm font-medium">{content}</span>
        <span className="flex justify-center items-center w-12 h-12 shrink-0">
          <X className="w-4 h-4 fill-current" />
        </span>
      </span>
      {duration !== "none" && (
        <span
          key={id}
          className={cx(scheme.progress.base, scheme.progress[type])}
          style={{ animationDuration: `${duration}ms` }}
        />
      )}
    </button>
  )
}

/**
 * ToastsContainer
 */
export const ToastsContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return createBodyPortal(
    <div className="fixed z-[51] bottom-12 top-14 left-1/2 flex flex-col justify-end items-center max-w-2xl gap-4 w-full px-16 -translate-x-1/2 pointer-events-none">
      {children}
    </div>
  )
}

/**
 * Toasts
 */
export const Toasts: React.FC<{ appendChildren?: React.ReactNode }> = props => {
  const toasts = useSortedToasts()
  return (
    <ToastsContainer>
      {A.map(toasts, toast => (
        <Toast key={toast.key} toast={toast} />
      ))}

      {props.appendChildren}
    </ToastsContainer>
  )
}
