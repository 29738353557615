import { Spinner as SpinnerSvg } from "@phosphor-icons/react"

/**
 * Spinner
 */
const Spinner: React.FC<{ className?: string }> = props => {
  return (
    <span>
      <SpinnerSvg
        className={cx("fill-primary-800", props.className, "animate-slow-rotate")}
        weight="regular"
      />
    </span>
  )
}

export default Spinner
