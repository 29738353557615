import globalConfig from "@/app/config/global"
import api from "@/app/services/api"
import { Patch } from "evolve-ts"
import { useAuthStore } from "."
import { resetAllStores } from ".."
import { localizeMe } from "./helpers"
import { AuthStoreState, Responses } from "./types"

const { setState: set } = useAuthStore
const setPartial = (patch: Patch<AuthStoreState>) => set(state => evolve(patch, state))

/**
 * init
 */
export const init = async (newVid: string) => {
  const vid = localStorage.getItem(`${globalConfig.sessionKey}-vid`) ?? newVid
  localStorage.setItem(`${globalConfig.sessionKey}-vid`, vid)

  const { success, data } = await api.get<Responses["get:session"]>("authentication/session")
  if (success) {
    const authenticated = data.session === true
    set({
      vid,
      initDone: true,
      initError: false,
      authenticated,
      user: authenticated ? localizeMe(data.user) : null,
    })

    return { error: false }
  }

  set({ vid, initDone: true, initError: true })
  return { error: true, code: data.code }
}

/**
 * signIn
 */
export const signIn = async (fd: { email: string; password: string }) => {
  const { success, data } = await api.post<Responses["post:sign-in"]>("authentication/sign-in", {
    data: fd,
  })
  if (success) {
    const { user, token } = data
    setPartial({ authenticated: true, user: localizeMe(user) })
    localStorage.setItem(globalConfig.sessionKey, token)
    return { error: false }
  }
  return { error: true, code: data.code }
}

/**
 * signOut
 */
export const signOut = async () => {
  const { success, data } = await api.get<Responses["get:sign-out"]>("authentication/sign-out")
  if (success) {
    setPartial({ authenticated: false, user: null })
    localStorage.removeItem(globalConfig.sessionKey)
    resetAllStores()
    return { error: false }
  }
  return { error: true, code: data.code }
}
