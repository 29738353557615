import { tcf } from "tcts"

export type ActionResult<
  S extends { [key: string]: any } | void = void,
  E extends { [key: string]: any } | void = void
> = Promise<
  S extends void
    ? { error: false } | (E extends void ? { error: true } : { error: true } & E)
    : ({ error: false } & S) | (E extends void ? { error: true } : { error: true } & E)
>

// store action tc
export const tcResult = tcf<{ error: true; code: "OTHER" }>({
  error: true,
  code: "OTHER",
})

/**
 * resetAllStores
 */

export const resetAllStores = () => {
  //
}
