/**
 * globalConfig
 */
const globalConfig = {
  api: process.env.VITE_API_URL ?? "",
  environment: process.env.VITE_ENV ?? "development",
  host: process.env.VITE_APP_URL ?? "",
  name: process.env.VITE_APP_NAME ?? "",
  siteName: "VP",
  maxUploadFile: +(process.env.VITE_MAX_UPLOAD_FILE ?? 32),
  sessionKey: process.env.VITE_APP_SESSION_KEY ?? "session",
}
export default globalConfig
