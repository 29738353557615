import globalConfig from "../config/global"

/**
 * isImageExtension
 */
export const isImageExtension = (extension: string) => A.includes(imageExtensions, extension)
export const imageExtensions = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "webp"]
export const documentExtensions = ["pdf", "doc", "docx"]

/**
 * isVideoExtension
 */
export const isVideoExtension = (extension: string) => A.includes(videoExtensions, extension)
export const videoExtensions = ["ogg", "mp4", "webm"]
export const typeFromExtension = (extension: string) => {
  if (extension === "mp4") return "video/mp4"
  if (extension === "ogg") return "video/ogg"
  return "video/webm"
}

/**
 * makePath
 */
export const makePath = (...paths: string[]) => {
  const trimmedPaths = paths.map((path) => path.replace(/^\/|\/$/g, ""))
  return trimmedPaths.join("/")
}

/**
 * makePathToApi
 */
export const makePathToApi = (...paths: string[]) => {
  return makePath(globalConfig.api, ...paths)
}

/**
 * makePathToApp
 */
export const makePathToApp = (...paths: string[]) => {
  return makePath(globalConfig.host, ...paths)
}
