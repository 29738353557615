import { byId } from "@/app/fns/byId"
import api from "@/app/services/api"
import { Patch } from "evolve-ts"
import { useLanguagesStore } from "."
import { localizeLanguage } from "./helpers"
import { Language, LanguagesStoreState, Responses } from "./types"

const { setState: set } = useLanguagesStore
const setPartial = (patch: Patch<LanguagesStoreState>) => set(state => evolve(patch, state))

/**
 * loadLanguages
 */

export const loadLanguages = async () => {
  const { success, data } = await api.get<Responses["get:languages"]>("languages")
  if (success) {
    const languages = byId(data.languages, localizeLanguage)
    setPartial({ languages, initDone: true })
    return { error: false }
  }
  return { error: true, code: data.code }
}

/**
 * createLanguage
 */

export const createLanguage = async (fd: Omit<Language, "id">) => {
  const { success, data } = await api.post<Responses["post:languages"]>(`languages`, { data: fd })
  if (success) {
    const language = localizeLanguage(data.language)
    set(evolve({ languages: evolve({ [language.id]: language }) }))
    return { error: false }
  }
  return { error: true, code: data.code }
}

/**
 * getLanguage
 */
export const getLanguage = async (id: Uuid) => {
  const { success, data } = await api.get<Responses["get:languages/:languageId"]>(`languages/${id}`)
  if (success) {
    set(evolve({ languages: D.set(id, localizeLanguage(data.language)) }))
    return { error: false }
  }
  return { error: true, code: data.code }
}

/**
 * updateLanguage
 */
export const updateLanguage = async ({ id, ...fd }: Extend<Partial<Language>, { id: Uuid }>) => {
  const { success, data } = await api.put<Responses["put:languages/:languageId"]>(
    `languages/${id}`,
    { data: fd }
  )
  if (success) {
    set(evolve({ languages: D.set(id, localizeLanguage(data.language)) }))
    return { error: false }
  }
  return { error: true, code: data.code }
}

/**
 * deleteLanguage
 */
export const deleteLanguage = async (id: Uuid) => {
  const { success, data } = await api.delete<Responses["delete:languages/:languageId"]>(
    `languages/${id}`
  )
  if (success) {
    set(evolve({ languages: D.deleteKey(id) }))
    return { error: false }
  }
  return { error: true, code: data.code }
}
