import { RouteAdmin } from "@/components/routes/RouteAdmin"
import { RouteAuthenticated } from "@/components/routes/RouteAuthenticated"
import { RouteMember } from "@/components/routes/RouteMember"
import { RouteUnauthenticated } from "@/components/routes/RouteUnauthenticated"
import { Redirect, Route, Switch } from "wouter"
import { Loader } from "./app-loader"
import { useMe } from "@/app/store/auth/hooks"

/**
 * website
 */
const WebsiteLayout = React.lazy(() => import("../components/layouts/Website"))
const Home = React.lazy(() => import("./"))
const Projects = React.lazy(() => import("./projects"))
const ProjectsProject = React.lazy(() => import("./projects/project"))
const KulturrallyeRoutes = React.lazy(() => import("./kulturrallye"))
const Program = React.lazy(() => import("./programs"))
const Contact = React.lazy(() => import("./contact"))
const Offer = React.lazy(() => import("./offer"))
const VpDay = React.lazy(() => import("./vp-day"))
const News = React.lazy(() => import("./news"))
const NewsArticle = React.lazy(() => import("./news/article"))
const LegalNotice = React.lazy(() => import("./legal-notice"))
const DataProtection = React.lazy(() => import("./data-protection"))
const SignIn = React.lazy(() => import("./sign-in"))
const SignOut = React.lazy(() => import("./sign-out"))

/**
 * Routes
 */
const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/dashboard/:rest*">
        <DashboardRoutes />
      </Route>

      {/* Auth pages */}

      <RouteUnauthenticated path="/sign-in">
        <FrontendSuspense>
          <SignIn />
        </FrontendSuspense>
      </RouteUnauthenticated>

      <RouteAuthenticated path="/sign-out">
        <FrontendSuspense>
          <SignOut />
        </FrontendSuspense>
      </RouteAuthenticated>

      {/* Website */}

      <Route path="/:rest*">
        <FrontendSuspense>
          <WebsiteLayout>
            <Switch>
              <Route path="/vp-day">
                <FrontendSuspense>
                  <VpDay />
                </FrontendSuspense>
              </Route>
              <Route path="/offer">
                <FrontendSuspense>
                  <Offer />
                </FrontendSuspense>
              </Route>
              <Route path="/contact">
                <FrontendSuspense>
                  <Contact />
                </FrontendSuspense>
              </Route>
              <Route path="/data-protection">
                <FrontendSuspense>
                  <DataProtection />
                </FrontendSuspense>
              </Route>
              <Route path="/legal-notice">
                <FrontendSuspense>
                  <LegalNotice />
                </FrontendSuspense>
              </Route>
              <Route path="/news/:id">
                {params => (
                  <FrontendSuspense>
                    <NewsArticle id={params.id} />
                  </FrontendSuspense>
                )}
              </Route>
              <Route path="/news">
                <FrontendSuspense>
                  <News />
                </FrontendSuspense>
              </Route>
              <RouteMember path="/projects" redirect="/">
                <FrontendSuspense>
                  <Projects />
                </FrontendSuspense>
              </RouteMember>
              <RouteMember path="/projects/:id" redirect="/">
                {params => (
                  <FrontendSuspense>
                    <ProjectsProject id={params.id} />
                  </FrontendSuspense>
                )}
              </RouteMember>
              <Route path="/programs">
                <FrontendSuspense>
                  <Program />
                </FrontendSuspense>
              </Route>
              <RouteMember path="/kulturrallye/:rest*">
                <FrontendSuspense>
                  <KulturrallyeRoutes />
                </FrontendSuspense>
              </RouteMember>
              <Route path="/">
                <FrontendSuspense>
                  <Home />
                </FrontendSuspense>
              </Route>
              <Redirect to="/" />
            </Switch>
          </WebsiteLayout>
        </FrontendSuspense>
      </Route>
    </Switch>
  )
}

/**
 * dashboard
 */
const DashboardLayout = React.lazy(() => import("../components/layouts/Dashboard"))
const Dashboard = React.lazy(() => import("./dashboard"))
const DashboardArticles = React.lazy(() => import("./dashboard/articles"))
const DashboardArticlesArticle = React.lazy(() => import("./dashboard/articles/article"))
const DashboardFiles = React.lazy(() => import("./dashboard/files"))
const DashboardPages = React.lazy(() => import("./dashboard/pages"))
const DashboardPagesPage = React.lazy(() => import("./dashboard/pages/page"))
const DashboardPrograms = React.lazy(() => import("./dashboard/programs"))
const DashboardPlaces = React.lazy(() => import("./dashboard/places"))
const DashboardPlacesPlace = React.lazy(() => import("./dashboard/places/place"))
const DashboardProjects = React.lazy(() => import("./dashboard/projects"))
const DashboardProjectsProject = React.lazy(() => import("./dashboard/projects/project"))
const DashboardReservations = React.lazy(() => import("./dashboard/reservations"))
const DashboardWorkshops = React.lazy(() => import("./dashboard/workshops"))
const DashboardWorkshopsWorkshop = React.lazy(() => import("./dashboard/workshops/workshop"))

const DashboardRoutes: React.FC = () => {
  const me = useMe()

  if (!me) return <Redirect to="/sign-in" />

  return (
    <DashboardSuspense>
      <DashboardLayout>
        <Switch>
          <RouteAdmin path="/dashboard">
            <DashboardSuspense>
              <Dashboard />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/articles/:id">
            {params => (
              <DashboardSuspense>
                <DashboardArticlesArticle id={params.id} />
              </DashboardSuspense>
            )}
          </RouteAdmin>
          <RouteAdmin path="/dashboard/articles">
            <DashboardSuspense>
              <DashboardArticles />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/reservations">
            <DashboardSuspense>
              <DashboardReservations />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/places/:id">
            {params => (
              <DashboardSuspense>
                <DashboardPlacesPlace id={params.id} />
              </DashboardSuspense>
            )}
          </RouteAdmin>
          <RouteAdmin path="/dashboard/places">
            <DashboardSuspense>
              <DashboardPlaces />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/workshops/:id">
            {params => (
              <DashboardSuspense>
                <DashboardWorkshopsWorkshop id={params.id} />
              </DashboardSuspense>
            )}
          </RouteAdmin>
          <RouteAdmin path="/dashboard/workshops">
            <DashboardSuspense>
              <DashboardWorkshops />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/pages/:id">
            {params => (
              <DashboardSuspense>
                <DashboardPagesPage id={params.id} />
              </DashboardSuspense>
            )}
          </RouteAdmin>
          <RouteAdmin path="/dashboard/pages">
            <DashboardSuspense>
              <DashboardPages />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/projects">
            <DashboardSuspense>
              <DashboardProjects />
            </DashboardSuspense>
          </RouteAdmin>
          <RouteAdmin path="/dashboard/projects/:id">
            {params => (
              <DashboardSuspense>
                <DashboardProjectsProject id={params.id} />
              </DashboardSuspense>
            )}
          </RouteAdmin>

          <RouteAdmin path="/dashboard/programs">
            <DashboardSuspense>
              <DashboardPrograms />
            </DashboardSuspense>
          </RouteAdmin>

          <RouteAdmin path="/dashboard/files">
            <DashboardSuspense>
              <DashboardFiles />
            </DashboardSuspense>
          </RouteAdmin>

          <Redirect to="/dashboard" />
        </Switch>
      </DashboardLayout>
    </DashboardSuspense>
  )
}

export default Routes

/**
 * FrontendSuspense
 */
const FrontendSuspense: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
}

/**
 * DashboardSuspense
 */
const DashboardSuspense: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
}
