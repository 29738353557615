import { container } from "@/styles/container"
import { VariantProps } from "class-variance-authority"

/**
 * Container
 */
type ContainerProps = Extend<React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof container>>
const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ x, l, r, size, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={cx(container({ x, l, r, size }), props.className)} />
    )
  }
)

export default Container
